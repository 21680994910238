import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
    Box,
    Button,
    createTheme,
    Stack,
    TextField,
    ThemeProvider, Typography
} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import DatePickerCustom from "../Date/DatePickerCustom";
import dayjs from "dayjs";

export default function NotificationsFilterDialog(
    title, masterOptions, filter, onFilterChange
) {
    const options = {
        customUI: view,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (
            <ThemeProvider theme={theme}>
                <Card className={"dialog-card"}>
                    <div
                        className='center'
                        style={{
                            position: 'relative',
                            minWidth: '320px',
                            width: "400px",
                            maxWidth: '90vw',
                            padding: '16px 0 16px 0'
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            onClick={() => {
                                onClose()
                            }}
                        >
                            <CloseIcon/>
                        </div>

                        <div>
                            <div style={{
                                color: '#05004E',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: '16px',
                                paddingLeft: '16px',
                                paddingRight: '46px'
                            }}
                                 dangerouslySetInnerHTML={{
                                     __html: title || '-'
                                 }}/>
                            <Divider/>
                        </div>

                        <div style={{maxHeight: '90vh', overflow: 'auto', minHeight: '150px', width: '100%' }}>
                            <DairyTripFilterDialogView
                                masterOptions={masterOptions}
                                filterData={filter}
                                onFilterChange={(value) => {
                                    if (onFilterChange) {
                                        onFilterChange(value)
                                    }
                                    onClose()
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </ThemeProvider>
        )
    }
}

function DairyTripFilterDialogView({ masterOptions, filterData, onFilterChange }) {
    const [filter, setFilter] = useState(filterData)

    return <Stack spacing={1}>
        <Stack spacing={2} sx={{ p: 2}}>
            <div>
                <Typography className="font-bold mb-1">
                    หมายเลขติดตาม
                </Typography>
                <TextField
                    value={filter.jobBuTracking}
                    fullWidth
                    size={'small'}
                    onChange={(event) => {
                        setFilter(prevState => ({
                            ...prevState,
                            jobBuTracking: event.target.value
                        }))
                    }}
                />
            </div>
            <div>
                <Typography className="font-bold mb-1">
                    ทะเบียนรถ
                </Typography>
                <TextField
                    value={filter.truckLicense}
                    fullWidth
                    size={'small'}
                    onChange={(event) => {
                        setFilter(prevState => ({
                            ...prevState,
                            truckLicense: event.target.value
                        }))
                    }}
                />
            </div>
            <div>
                <Typography className="font-bold mb-1">
                    ปลายทาง
                </Typography>
                <TextField
                    value={filter.destinationSiteName}
                    fullWidth
                    size={'small'}
                    onChange={(event) => {
                        setFilter(prevState => ({
                            ...prevState,
                            destinationSiteName: event.target.value
                        }))
                    }}
                />
            </div>
            <div>
                <Typography className="font-bold mb-1">
                    ประเภทรถ
                </Typography>
                <Autocomplete
                    value={filter.buTruckType}
                    fullWidth
                    disablePortal
                    options={masterOptions}
                    getOptionLabel={(option) => option.display} // Display label text
                    onChange={(event, newValue) => {
                        setFilter(prevState => ({
                            ...prevState,
                            buTruckType: newValue
                        }))
                    }}
                    renderInput={(params) =>
                        <TextField {...params} size={"small"} />
                    }
                />
            </div>
        </Stack>

        <Divider/>

        <Box sx={{
            display: 'flex',
            gap: 2, pl: 2, pr: 2, pt: 2,
            justifyContent: 'right'
        }}>
            <Button
                disabled={!validateButton(filter)}
                fullWidth
                style={{ color: 'white' }}
                variant="contained"
                color="info"
                onClick={() => {
                    if (onFilterChange) {
                        onFilterChange(filter)
                    }
                }}
            >
                ค้นหา
            </Button>
        </Box>
    </Stack>

    function validateButton(filter) {
        if ((filter?.truckLicense?.length ?? 0) <= 4 && (filter?.truckLicense?.length ?? 0) > 0) {
            return false
        }
        else if ((filter?.destinationSiteName?.length ?? 0) <= 4 && (filter?.destinationSiteName?.length ?? 0) > 0) {
            return false
        }

        return true
    }
}

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit',
        },
    }
}

const theme = createTheme({
    components: {
        MuiTypography: styleOverride,
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    },
    palette: {
        primary: {
            main: '#302E2E',
        },
        secondary: {
            main: '#747474',
        },
        info: {
            main: '#0D99FF',
        },
        success: {
            main: '#38b000',
        },
        error: {
            main: '#d62828',
        },
        warning: {
            main: '#B64648',
        },
    },
});