import axios from "axios";
import {API} from "../../config/api";

export default class ApiImportFileXlsx {
    
    static async import(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'importFileXlsx',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                    }}
            );
        } catch (e) {
            return e;
        }
    }

    static async getTrukrPerformance(id) {
        const req = {
            importFileResultsId: id
        }

        try {
            return await axios.post(
                new API().getCusAPI()+'lotus/getTrukrPerformance',
                req,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    }
                }
            );
        } catch (e) {
            return e;
        }

        // return {
        //     status: 200,
        //     data: {
        //         filter: "{\"buType\":\"LPRDC,LPRFDC\",\"type\":\"LPRDC\",\"updateStart\":\"01\\/01\\/2025, 11:53:42\",\"updateEnd\":\"11\\/03\\/2025, 11:53:42\",\"debrief\":\"false\",\"trukrStatusList\":[\"1\",\"3\",\"4\"],\"minDateDecrease\":null,\"mockData\":null,\"updateBuTrackingList\":[\"1587492\"]}",
        //         jobCnt: 1,
        //         shipmentCnt: 7,
        //         actionJobCreateCnt: 0,
        //         actionShipmentCreateCnt: 0,
        //         actionJobUpdateCnt: 1,
        //         actionShipmentUpdateCnt: 5,
        //         actionJobInfo: {
        //             addDropCnt: 1,
        //             removeDropCnt: 0,
        //             swapSequenceCnt: 0
        //         },
        //         actionShipmentInfo: {
        //             addDropCnt: 2,
        //             removeDropCnt: 0,
        //             swapSequenceCnt: 0
        //         },
        //         startTrukrDatetime: "2025-03-12 10:46:52",
        //         endTrukrDatetime: "2025-03-12 10:46:57",
        //         endTrukrProcessTxt: "00:04 นาที",
        //         endTrukrProcessDatetime: "2025-03-12 10:46:57",
        //         endProcessTxt: "00:17 นาที"
        //     }
        // }
    }

    static async test(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'importFileXlsx',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                    }}
            );
        } catch (e) {
            return e;
        }
    }

}