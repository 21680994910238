import {confirmAlert} from "react-confirm-alert";
import {Button, createTheme, Stack, ThemeProvider} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from "@mui/material/Grid";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit', fontSize: 16,
        },
    }
}

const theme = createTheme({
    components: {
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    }, palette: {
        primary: {
            main: '#302E2E',
        }, secondary: {
            main: '#747474',
        }, info: {
            main: '#397EF5',
        }, error: {
            main: '#B64648',
        }, warning: {
            main: '#B64648',
        },
    },
});

export default function TrukrPerformanceDialog(title, subTitle, data, truckrStatus) {
    const options = {
        customUI: view, closeOnEscape: false, closeOnClickOutside: false, overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (<ThemeProvider theme={theme}>
            <Card>
                <div
                    className='center'
                    style={{
                        position: 'relative', minWidth: '450px', maxWidth: '90vw', padding: '16px 0 16px 0'
                    }}
                >
                    <div
                        style={{
                            cursor: 'pointer', width: '24px', height: '24px', position: 'absolute', right: '10px'
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon/>
                    </div>

                    <div>
                        <div style={{
                            fontSize: '22px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}
                             dangerouslySetInnerHTML={{
                                 __html: title || '-'
                             }}
                        />
                        <div style={{
                            fontSize: '18px',
                            textAlign: 'left',
                            marginBottom: '10px',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}
                             dangerouslySetInnerHTML={{
                                 __html: subTitle || '-'
                             }}
                        />
                    </div>

                    <TrukrPerformanceView
                        data={data}
                        truckrStatus={truckrStatus}
                    />
                </div>
            </Card>
        </ThemeProvider>)
    }
}

function TrukrPerformanceView(props) {
    useEffect(() => {
    }, [])

    return <div style={{
        maxHeight: '80vh',
        maxWidth: '600px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        overflow: 'auto'
    }}>
        <SectionView title="Filter Information" />
        <TopView filterJsonStr={props.data?.filter} truckrStatus={props.truckrStatus} />

        <SectionView title="Import Information" />
        <CenterView data={props.data} />

        <SectionView title="Performance" />
        <BottomView data={props.data} />
    </div>
}

function SectionView({title}) {
    return <div style={{
        backgroundColor: '#C3D3E2',
        padding: '5px 10px',
        fontWeight: '800'
    }}>
        {title}
    </div>
}

function TopView({filterJsonStr, truckrStatus}) {
    const dataFilter = JSON.parse(filterJsonStr)

    return <Stack sx={{mb: 2, mt: 2}}>
        <Grid container spacing={1}>
            <Grid item xs={3}>
                {item("Import Type:", dataFilter.action ?? "-")}
            </Grid>
            <Grid item xs={3}>
                {item("BU:", dataFilter.type ?? "-")}
            </Grid>
            <Grid item xs={3}>
                {item("Min start date:", dataFilter.minDateDecrease ?? "-")}
            </Grid>
            <Grid item xs={3}>
                {item("Update date from:", dataFilter.updateStart ?? "-")}
            </Grid>
            <Grid item xs={3}>
                {item("Update date to:", dataFilter.updateEnd ?? "-")}
            </Grid>
            <Grid item xs={3}>
                {item("Debrief:", dataFilter.debrief ?? "-")}
            </Grid>
            <Grid item xs={3}>
                {item("Trukr status:", getTrukrStatusList(truckrStatus, dataFilter.trukrStatusList ?? []))}
            </Grid>
            <Grid item xs={3}>
                {item("BU Type:", dataFilter.buType ?? "-")}
            </Grid>
        </Grid>
    </Stack>

    function item(title, subTitle) {
        return <Stack>
            <div style={{ fontSize: '14px' }}>{title}</div>
            <div style={{ fontSize: '14px', fontWeight: '800' }}>{subTitle}</div>
        </Stack>
    }

    function getTrukrStatusList(trukrStatusList, idList) {
        let value = ""

        idList?.forEach((m, index) => {
            value += trukrStatusList.find(f => f.id == m)?.name ?? "-"
            if (idList.length != (index + 1)) {
                value += ", "
            }
        })

        return value
    }
}

function CenterView({data}) {
    function sectionTitle(title, subTitle, color) {
        return <div style={{ color: color ?? "black", whiteSpace: "nowrap" }}>
            {title} {subTitle && <span className="font-bold pl-1">{subTitle}</span>}
        </div>
    }

    function item(itemData, create, update) {
        function getUpdateSummary(update, add, remove, swap) {
            return (update ?? 0) - ((add ?? 0) - (remove ?? 0) - (swap ?? 0))
        }

        return <>
            <Stack
                sx={{ fontSize: '14px', mt: 1 }}
                direction="row"
                spacing={1}
                alignItems={'center'}
                useFlexGap
            >
                {sectionTitle("Create:", create ?? "0")}
                {sectionTitle("|", undefined, 'lightgray')}
                {sectionTitle("Update:", update ?? "0")}
                <div style={{padding: '5px 10px', backgroundColor: '#DCECFF'}}>
                    <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginTop: '2px', flexWrap: 'wrap' }}
                        useFlexGap
                    >
                        {sectionTitle("Update:", itemData?.updateCnt ?? "0")}
                        {sectionTitle("Add Drop:", itemData?.addDropCnt ?? "0")}
                        {sectionTitle("Remove Drop:", itemData?.removeDropCnt ?? "0")}
                        {sectionTitle("Change Sequence:", itemData?.swapSequenceCnt ?? "0")}
                    </Stack>
                </div>
            </Stack>
        </>
    }

    return (
        <Stack sx={{mb: 2, mt: 2}}>
            <Stack direction="row" spacing={1} alignItems={'center'}>
                {
                    sectionTitle("จำนวน Trip:", data.jobCnt)
                }
                <div style={{fontSize: '12px'}}>( Trukr )</div>
            </Stack>
            {
                item(
                    data.actionJobInfo,
                    data.actionJobCreateCnt,
                    data.actionJobUpdateCnt
                )
            }

            <div style={{ height: '10px' }} />

            <Stack direction="row" spacing={1} alignItems={'center'}>
                {
                    sectionTitle("จำนวน Shipment:", data.shipmentCnt)
                }
                <div style={{ fontSize: '12px' }}>( Trukr )</div>
            </Stack>
            {
                item(
                    data.actionShipmentInfo,
                    data.actionShipmentCreateCnt,
                    data.actionShipmentUpdateCnt
                )
            }
        </Stack>
    )
}

function BottomView({data}) {
    return <Stack sx={{mb: 1, mt: 2}}>
        <Stack direction="row" justifyContent={'start'} spacing={2} sx={{ mb: 1 }}>
            <div>เวลาที่เริ่ม:</div>
            <div className={"font-bold"}>
                {data.startTrukrDatetime ?? "-"}
            </div>
        </Stack>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <div style={{fontSize: '16px', fontWeight: '800'}}>เวลาที่ Trukr ใช้</div>
                <Stack direction="row" justifyContent={'space-between'}>
                    <div>เวลาสิ้นสุด:</div>
                    <div className={"font-bold"}>
                        {data.endTrukrDatetime ?? "-"}
                    </div>
                </Stack>
                <Stack direction="row" justifyContent={'space-between'}>
                    <div>เวลาที่ใช้ไป:</div>
                    <div className={"font-bold"}>
                        {data.endTrukrProcessTxt ?? "-"}
                    </div>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <div style={{fontSize: '16px', fontWeight: '800'}}>เวลาที่ CMP ใช้ในการ Process</div>
                <Stack direction="row" justifyContent={'space-between'}>
                    <div>เวลาสิ้นสุด:</div>
                    <div className={"font-bold"}>
                        {data.endProcessDatetime ?? "-"}
                    </div>
                </Stack>
                <Stack direction="row" justifyContent={'space-between'}>
                    <div>เวลาที่ใช้ไป:</div>
                    <div className={"font-bold"}>
                        {data.endProcessTxt ?? "-"}
                    </div>
                </Stack>
            </Grid>
        </Grid>
    </Stack>
}