import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Chip, ClickAwayListener, Stack} from "@mui/material";
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {useEffect, useRef, useState} from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {copyTextToClipboard} from "../../util/Text";
import Tooltip from "@mui/material/Tooltip";

export default function CustomNotificationAccordion(props) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {threshold: 0.1}
        );

        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return (
        <div style={{minHeight: '160px'}} ref={ref}>
            {isVisible &&
                <Accordion sx={{borderRadius: '6px'}} disableGutters square>
                    <AccordionSummary
                        expandIcon={(props.list?.length ?? 0) === 0 ? undefined : <ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <NotificationDetail
                            isExpandIcon={(props.list?.length ?? 0) > 0}
                            jobBuTracking={props.jobBuTracking}
                            data={props.notiData}
                        />
                    </AccordionSummary>

                    {
                        props.list ?
                            <AccordionDetails>
                                <Timeline

                                    sx={{
                                        mt: 0,
                                        mb: 0,
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}
                                >
                                    {
                                        props.list?.map((timeline, index) =>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector
                                                        sx={{width: '1.5px'}}
                                                    />
                                                    <TimelineDot
                                                        sx={{mt: '3px', mb: '4px', width: '13px', height: '12px'}}
                                                    />
                                                    <TimelineConnector
                                                        sx={{width: (props.list?.length ?? 0) === (index + 1) ? '0px' : '1.5px'}}
                                                    />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{py: '12px', px: 2}}>
                                                    <NotificationDetail
                                                        isDetail={true}
                                                        jobBuTracking={props.jobBuTracking}
                                                        data={timeline}
                                                    />
                                                </TimelineContent>
                                            </TimelineItem>
                                        )
                                    }
                                </Timeline>
                            </AccordionDetails> : null
                    }
                </Accordion>
            }
        </div>
    )
}

function NotificationDetail(props) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <Stack sx={{mt: 1, mb: 1, position: 'relative'}} direction={'column'}>
            <Typography
                component="span"
                sx={{
                    fontWeight: 'bold',
                    color: props.isDetail ? '#797979' : '#05004E'
                }}
            >
                {props.data?.title ?? "-"}
            </Typography>

            {
                props.isDetail ? null :
                    <Chip
                        sx={{
                            mt: 0.5,
                            pl: 1,
                            pr: 1,
                            color: "white",
                            backgroundColor: "#DA3A3A",
                            width: 'fit-content',
                            fontSize: '11px',
                            fontWeight: 'bold'
                        }}
                        label={props.data?.buName ?? "-"}
                        size="small"
                    />
            }

            <Stack
                direction={"row"}
                spacing={2}
                sx={{position: "absolute", top: "0px", right: props.isExpandIcon ? '-24px' : '0px'}}
            >
                <div
                    style={{ color: props.isDetail ? '#797979' : '#05004E' }}
                >
                    {props.data?.createDate ?? ""}
                </div>

                {
                    !props.isDetail &&
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                            <Tooltip
                                placement="left"
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied!"
                                slotProps={{
                                    popper: {
                                        disablePortal: true,
                                    },
                                }}
                            >
                                <ContentCopyIcon
                                    onClick={(event) => {
                                        event.stopPropagation();

                                        let text = `${props.data?.title ?? "-"}\n${props.data?.buName ?? "-"}`
                                        text += `\nคุณมี Order No. ${props.jobBuTracking ?? "-"} ${props.data?.title ?? ""}`
                                        text += `\nต้นทาง: ${props.data?.originSite ?? "-"}  ปลายทาง: ${props.data?.destinationSite ?? "-"}`
                                        text += `\nอุณหภูมิ: ${props.data?.temparature ?? "-"}  Vendor: ${props.data?.vendorName ?? "-"}  ประเภทรถ: ${props.data?.truckTypeName ?? "-"}`
                                        text += `\nทะเบียนหัว: ${props.data?.vehicleNumberPlate ?? "-"} เอกสาร: ${props.data?.remark ?? "-"}`
                                        text += `\nพขร.: ${props.data?.driverName ?? "-"}  โทร: ${props.data?.driverTel ?? "-"}`
                                        text += `\nวันที่รับหาง: ${props.data?.originPlanPickupDate ?? '-'} วันที่ส่ง: ${props.data?.destinationExpectedDeliveryDate ?? '-'}`

                                        copyTextToClipboard(text, (isSuccess) => {
                                            if (isSuccess) {
                                                handleTooltipOpen(true)
                                            }
                                        })
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                }
            </Stack>

            <Stack
                sx={{
                    pr: 1,
                    mt: props.isDetail ? 1 : 1.5,
                    flexWrap: 'wrap',
                    color: '#797979'
                }}
                spacing={1.2}
                useFlexGap
                direction={'row'}
            >
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"Order No."}
                        second={props.jobBuTracking ?? "-"}
                        third={props.data?.title}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"ต้นทาง :"}
                        second={props.data?.originSite ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"ปลายทาง :"}
                        second={props.data?.destinationSite ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"อุณหภูมิ :"}
                        second={props.data?.temparature ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"Vendor :"}
                        second={props.data?.vendorName ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"ประเภทรถ :"}
                        second={props.data?.truckTypeName ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"ทะเบียนหัว :"}
                        second={props.data?.vehicleNumberPlate ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"เอกสาร :"}
                        second={props.data?.remark ?? "-"}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"พขร. :"}
                        second={props.data?.driverName ?? '-'}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"โทร. :"}
                        second={props.data?.driverTel ?? '-'}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"วันที่รับหาง :"}
                        second={props.data?.originPlanPickupDate ?? '-'}
                    />
                </Typography>
                <Typography
                    component="span"
                    sx={{fontSize: '15px'}}
                >
                    <NotificationItem
                        first={"วันที่ส่ง :"}
                        second={props.data?.destinationExpectedDeliveryDate ?? '-'}
                    />
                </Typography>
            </Stack>
        </Stack>
    )

    function NotificationItem({first, second, third}) {
        return <Typography
            component="span"
            sx={{fontSize: '15px'}}
        >
            {first}
            <span style={{
                fontWeight: '600',
                color: props.isDetail ? '#797979' : '#000000',
                paddingRight: '5px',
                paddingLeft: '5px'
            }}>
                {second}
            </span>
            {
                third &&
                <span style={{paddingRight: '5px'}}>
                    {third}
                </span>
            }
        </Typography>
    }
}