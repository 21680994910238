export default function MessageComponent(props) {
    return <div id="notificationContainer">
        <div id="imageContainer">
            <img src={props.notification.image ?? '/assets/app-icon.png'} width={35}/>
        </div>

        <div id="notificationBody">
            <div id="notificationHeader">{props.notification.title}</div>
            <div>{props.notification.body}</div>
        </div>
    </div>
}