import {Children, useEffect, useRef, useState} from "react";

export default function LazyLoadComponent({key, minHeight, children, callbackLoaded}) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);

                    if (callbackLoaded) {
                        callbackLoaded()
                    }

                    observer.disconnect(); // หยุดสังเกตเมื่อโหลดแล้ว
                }
            },
            {threshold: 0.1}
        );

        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return <div style={{ minHeight: minHeight }} key={key} ref={ref}>{isVisible && children}</div>
}