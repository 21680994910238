import axios from "axios";
import {API} from "../../config/api";

export default class ApiNotification {
    static async getBuInfoByBuCode(req){
        try {
            return await axios.post(
                new API().getCusAPI()+'masters/get-bu-info-by-bu-code',
                req,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }
                }
            );
        } catch (e) {
            return e.toLocaleString();
        }
    }

    static async getTruckTypeListByBu(req){
        try {
            return await axios.post(
                new API().getCusAPI()+'masters/get-truck-type-list-by-bu',
                req,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }
                }
            );
        } catch (e) {
            return e.toLocaleString();
        }
    }

    static async getNotificationList(req){
        try {
            return await axios.post(
                new API().getCusAPI()+'order-management/get-truck-assign-history',
                req,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }
                }
            );
        } catch (e) {
            return e.toLocaleString();
        }
    }
}