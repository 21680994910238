import {Chip, Stack} from "@mui/material";
import {useEffect, useState} from "react";

export default function CustomChipsComponent(props) {
    useEffect(() => {

    }, [])

    return(
        <Stack
            direction={'row'}
            spacing={1}
            sx={{ flexWrap: 'wrap' }}
            useFlexGap
        >
            {
                props.options?.map((value) =>
                    <Chip
                        color={ props.value?.name === value.name ? 'primary' : 'default' }
                        label={value.name}
                        variant="outlined"
                        onClick={() => {
                            if (props.onChange) {
                                props.onChange(value)
                            }
                        }}
                    />
                )
            }
        </Stack>
    )
}