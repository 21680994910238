import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import * as PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import TapsComponent from "../components/TapsComponent";
import CustomNotificationAccordion from "../components/notificationComponent/CustomNotificationAccordion";
import CustomChipsComponent from "../components/notificationComponent/CustomChipsComponent";
import Typography from '@mui/material/Typography';
import ApiNotification from "../services/api/ApiNotification";
import Swal from "sweetalert2";
import LazyLoadComponent from "../components/LazyLoadComponent";
import NotificationsFilterDialog from "../components/notificationComponent/NotificationsFilterDialog";
import {Skeleton} from "@mui/lab";
import {AuthContext} from "../App";
import {copyTextToClipboard} from "../util/Text";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

function CustomTabPanel(props) {
    return null;
}

CustomTabPanel.propTypes = {
    index: PropTypes.number,
    children: PropTypes.node
};

export default function NotificationPage() {
    const { profiles } = useContext(AuthContext);

    const [optionsTap, setOptionsTap] = useState([
        "All",
        "Assigned",
        "Updated"
    ])

    const [optionsChip, setOptionsChip] = useState([])
    const [optionsTruck, setOptionsTruck] = useState([])

    const [filter, setFilter] = useState({
        buTruckType: null,
        jobBuTracking: "",
        truckLicense: "",
        destinationSiteName: ""
    })
    const [tapSelected, setTapSelected] = useState("All")
    const [chipSelected, setChipSelected] = useState(null)
    const [notificationList, setNotificationList] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [resultPage, setResultPage] = useState(
        {
            page:0,
            perPage:0,
            total:0,
            totalPage:0
        }
    )
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (chipSelected && tapSelected) {
            getMasterTruck()
            getNotificationList(filter)
        }
    }, [chipSelected, tapSelected])

    useEffect(() => {
        if (profiles) {
            getMasterBu()
        }
    }, [profiles])

    async function getMasterBu() {
        setIsLoad(true)

        const res = await ApiNotification.getBuInfoByBuCode({
            buCode: profiles.data?.bu ?? []
        })

        if (res.status === 200) {
            const buList = res.data
            if (buList) {
                // const filterBu = buList.filter(f =>
                //     f.name?.toLowerCase()?.includes("makro") ||
                //     f.name?.toLowerCase()?.includes("lotus")
                // )

                const filterBu = buList

                setOptionsChip(filterBu ?? [])
                setChipSelected(filterBu ? filterBu[0] : null)
            }
        } else {
            Swal.fire({
                title: "Request failed!",
                text: res.toLocaleString(),
                icon: "error"
            });
        }

        setIsLoad(false)
    }

    async function getMasterTruck() {
        setIsLoad(true)

        const res = await ApiNotification.getTruckTypeListByBu({
            buType: getBuType(chipSelected.name)
        })

        if (res.status === 200) {
            setOptionsTruck(res.data ?? [])
        } else {
            Swal.fire({
                title: "Request failed!",
                text: res.toLocaleString(),
                icon: "error"
            });
        }

        setIsLoad(false)
    }

    async function getNotificationList(filterValue, isLoadMore) {
        if (isLoadMore) {
            setIsLoadMore(true)
        } else {
            setIsLoad(true)
        }

        const res = await ApiNotification.getNotificationList({
            type: tapSelected,
            buId: chipSelected.id,
            jobBuTracking: filterValue?.jobBuTracking,
            truckLicense: filterValue?.truckLicense,
            buTruckType: filterValue?.buTruckType?.name,
            destinationSiteName: filterValue?.destinationSiteName,
            page: isLoadMore ? page : 1,
            perPage: 30
        })

        if (res.status === 200 && res.data) {
            const { page, perPage, total, totalPage, dataList } = res.data
            setResultPage({
                page:page,
                perPage:perPage,
                total:total,
                totalPage:totalPage
            })

            if (!isLoadMore) {
                setNotificationList(dataList ?? [])
                setPage(2)
            } else {
                if (dataList) {
                    let list = [...notificationList]

                    dataList.forEach(value => {
                        list.push(value)
                    })

                    setNotificationList(list)
                    setPage(prevState => prevState + 1)
                }
            }
        } else {
             Swal.fire({
                title: "Request failed!",
                text: res.toLocaleString(),
                icon: "error"
            });
        }

        if (isLoadMore) {
            setIsLoadMore(false)
        } else {
            setIsLoad(false)
        }
    }

    return(
        <Box>
            <Container
                maxWidth={'xl'}
                sx={{ mb: 3, mt: 3, px: { xs: 0, md: 3 } }}
            >
                <Stack
                    sx={{ mb: 4 }}
                    direction="row"
                    spacing={2}
                    justifyContent={'space-between'}
                >
                    <div>
                        <Typography
                            variant="h1" gutterBottom
                            sx={{ color: '#05004E', fontWeight: '600', fontSize: '20px' }}
                        >
                            Notification List
                        </Typography>
                        <Typography
                            variant='h4'
                            sx={{ color: '#05004E' }}
                        >
                            รายการแจ้งเตือน
                        </Typography>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                        NotificationsFilterDialog(
                            "Filter by",
                            optionsTruck ?? [],
                            filter,
                            (value) => {
                                setFilter(value)
                                getNotificationList(value)
                            }
                        )
                    }}>
                        <FilterAltIcon sx={{ color: '#05004E', width: '30px', height: '30px' }}/>
                    </div>
                </Stack>

                <Paper
                    sx={{ p: 1, borderRadius: '6px' }}
                    elevation={0}
                >
                    <Box sx={{ width: '100%', mb: 3 }}>
                        <TapsComponent
                            options={optionsTap}
                            value={tapSelected}
                            onChange={(changeValue) => {
                                setTapSelected(changeValue)
                                console.log("====== Debug taps change: ", changeValue)
                            }}
                        />
                    </Box>

                    <Box sx={{ width: '100%', mb: 1 }}>
                        <CustomChipsComponent
                            options={optionsChip}
                            value={chipSelected}
                            onChange={(changeValue) => {
                                setChipSelected(changeValue)
                                console.log("====== Debug chip change: ", changeValue)
                            }}
                        />
                    </Box>
                </Paper>

                {
                    isLoad ? loadingView() :
                        <Box sx={{ width: '100%', mt: 3,  }}>
                            {
                                notificationList.map((notiData, index) =>
                                    <LazyLoadComponent
                                        key={"lazy-load-component-" + index}
                                        minHeight={'160px'}
                                        callbackLoaded={() => {
                                            let isEnd = (index + 1) === (notificationList.length ?? 0)
                                            let isEndPage = resultPage.totalPage < page
                                            console.log(
                                                "lazy-load-component-" +
                                                index,
                                                isEnd,
                                                isEndPage,
                                                page,
                                                resultPage.totalPage
                                            )

                                            if (isEnd && !isEndPage) {
                                                getNotificationList(filter, true)
                                            }
                                        }}
                                    >
                                        <Box sx={{ width: '100%', mt: 1.5, mb: 1.5 }}>
                                            <CustomNotificationAccordion
                                                jobBuTracking={notiData.jobBuTracking}
                                                notiData={notiData?.truckAssignHistory ? notiData?.truckAssignHistory[0] : []}
                                                list={notiData?.truckAssignHistory?.slice(1) ?? []}
                                            />
                                        </Box>
                                    </LazyLoadComponent>
                                )
                            }
                        </Box>
                }

                {
                    (!isLoad && (notificationList?.length ?? 0) === 0) &&
                        <div className="empty-content">
                            ไม่พบข้อมูล
                        </div>
                }

                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={isLoadMore}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Box>
    )

    function loadingView() {
        return <Box sx={{ mt: 3 }}>
            <Skeleton className={"loading-con"} variant="rectangular" height={230}/>
            <Skeleton className={"loading-con"} variant="rectangular" height={230}/>
            <Skeleton className={"loading-con"} variant="rectangular" height={230}/>
        </Box>
    }

    function getBuCode() {
        const buCodeList = profiles.data?.bu
        const result = buCodeList?.map(num => ({ buCode: num }));
        return result
    }

    function getBuType(buName) {
        if (buName?.toLowerCase()?.includes("makro")) {
            if (buName?.toLowerCase()?.includes("dry")) {
                return "makroDry"
            }

            return "makro"
        }
        else if (buName?.toLowerCase()?.includes("all now complex")) {
            return "anc"
        }
        else {
            return "lotus"
        }
    }
}