import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBdZakn_UvFFRAkBC4Yh6gAoBqc3RaZ7nQ",
  authDomain: "all-now-free-service.firebaseapp.com",
  projectId: "all-now-free-service",
  storageBucket: "all-now-free-service.firebasestorage.app",
  messagingSenderId: "774197979659",
  appId: "1:774197979659:web:5a2874873b639ffb7f750e",
  measurementId: "G-GTNM0JSGSF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
