import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function TapsComponent(props) {
    return(
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                aria-label="basic tabs example"
                value={props.value}
                onChange={(event, value) => {
                    if (props.onChange) {
                        props.onChange(value)
                    }
                }}
            >
                {
                    props.options?.map(m =>
                        <Tab value={m} label={m} />
                    )
                }
            </Tabs>
        </Box>
    )
}