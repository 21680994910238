import React, {useEffect, useState} from 'react';
import {API} from "../config/api";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import FileDownload from "js-file-download";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {dateFormatYYYYMMDD} from "../util/Date";
import { isLineBrowser } from "../util/browserUtils";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {LoadingButton} from "@mui/lab";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomIOSSwitchComponent from "../components/CustomIOSSwitchComponent";

const masterData = {
    rq: [
        {
            name: "เฉพาะที่มี RQ",
            id: 1
        },
        {
            name: "ไม่มี RQ",
            id: 2
        }
    ],
    wsl: [
        {
            name: "ส่งแล้ว",
            id: 1
        },
        {
            name: "ยังไม่ส่ง",
            id: 2
        }
    ]
}

export default function TransportationCPF(props) {
    const pathApi = new API().getCusAPI();
    const [loading, setLoading] = useState(false);
    const [loadingWSL, setLoadingWSL] = useState(false);
    const [loadingReadable, setLoadingReadable] = useState(false);
    const [isWsl, setIsWSL] = useState(false);

    // ------------ ค้นหาข้อมูลตามวันที่ ---------------
    const {
        getValues: getValues,
        register: register,
        handleSubmit: handleSubmitSearch,
        reset: resetInsert,
        control
    } = useForm();

    useEffect(() => {
        // if (isLineBrowser()) {
        //     alert('Is line browser');
        // }
    }, [])

    const SearchDateForDowload = (e) => {
        e.preventDefault();

        const target = e.nativeEvent.submitter.name ?? ""
        let values = getValues()

        if (target === "ExportReadable") {
            setLoadingReadable(true)
        }
        if (target === "ExportWSL") {
            setLoadingWSL(true)
        }

        // const startDate = new Date(values.posting_date_start)
        // const currentTime = new Date()
        // const timestamp = currentTime.getTime()

        // const newTimeSixDigit = String(timestamp).slice(-6)
        // const formattedStartDate = dateFormatYYYYMMDD(startDate);

        if (
            (values.posting_date_start && values.posting_date_end) ||
            (values.rq_date_start && values.rq_date_end)
        ) {
            values.is_send_sftp = isWsl
            let request = {
                module: "TransportationCPF",
                target: target,
                data: values
            }

            axios
                // .post('http://localhost:8000/api/transportation-export-wsl', request, {
                    .post(pathApi + 'api/transportation-export-wsl', request, {
                    headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    responseType: 'arraybuffer'
                })
                .then(function (response) {
                    const fileName = getFilename(response)

                    if (target === "ExportReadable") {
                        setLoadingReadable(false)
                    }
                    if (target === "ExportWSL") {
                        setLoadingWSL(false)
                    }

                    switch (target) {
                        case 'ExportWSL':
                            FileDownload(response.data, fileName)
                            break;
                        case 'ExportReadable':
                            FileDownload(response.data, fileName)
                            break;
                        default:
                            break;
                    }
                })
                .catch(function (error) {
                    if (target === "ExportReadable") {
                        setLoadingReadable(false)
                    }
                    if (target === "ExportWSL") {
                        setLoadingWSL(false)
                    }

                    let message = ""
                    let title = ""

                    switch (error.response.status) {
                        case 404:
                            title = "ไม่พบข้อมูล"
                            message = undefined
                            break;
                        case 400:
                            title = "Bad request"
                            message = "Status code: " + error.response.status
                            break;
                        default:
                            title = "เกิดข้อผิดพลาดในการ download ไฟล์"
                            message = undefined
                            break;
                    }

                    Swal.fire({
                        title: title,
                        text: message,
                        icon: error.response.status === 404 ? "warning" : "error",
                        confirmButtonText: "OK",
                    }).then(() => {});
                });
        } else {
            if (target === "ExportReadable") {
                setLoadingReadable(false)
            }
            if (target === "ExportWSL") {
                setLoadingWSL(false)
            }

            Swal.fire({
                title: "ข้อมูลไม่ครบ",
                text: "กรุณาเลือกวันเริ่มต้นและวันสิ้นสุด",
                icon: "error",
                confirmButtonText: "OK",
            }).then(() => {});
        }
    };

    return (
        <>
            {
                loading ? <LinearProgress /> : <></>
            }

            <form
                onSubmit={SearchDateForDowload}
                // onSubmit={handleSubmitSearch(SearchDateForDowload)}
            >
                <Grid container spacing={2} sx={{ padding: "15px" }}>
                    <Grid xs={12}>
                        <Typography style={{ fontWeight: 'bold' }} fontSize={"20px"}>ออกรายงานเที่ยววิ่ง CPF</Typography>
                    </Grid>

                    <div className="dv-line opa-2" style={{ marginTop: '16px' }} />

                    <Grid container sx={12}>
                        <Grid xs={12} style={{ marginTop: '18px' }}>
                            <Typography style={{ fontWeight: 'bold' }} fontSize={"16px"}>วันที่ทำ RQ</Typography>
                        </Grid>
                        <Grid xs={12} md={6} style={{ marginTop: '0px' }}>
                            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>
                                จากวันที่
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Controller
                                    name="rq_date_start"
                                    control={control}
                                    defaultValue={null} // ค่าเริ่มต้น
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            views={['day']}
                                            openTo="day"
                                            format="DD/MM/YYYY"
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                                            slotProps={{
                                                textField: { size: 'small', fullWidth: true },
                                                actionBar: {
                                                    actions: ['clear'],
                                                },
                                            }}
                                            required
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} md={6} style={{ marginTop: '0px' }}>
                            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>
                                ถึงวันที่
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Controller
                                    name="rq_date_end"
                                    control={control}
                                    defaultValue={null} // ค่าเริ่มต้น
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            views={['day']}
                                            openTo="day"
                                            format="DD/MM/YYYY"
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                                            slotProps={{
                                                textField: { size: 'small', fullWidth: true },
                                                actionBar: {
                                                    actions: ['clear'],
                                                },
                                            }}
                                            required
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} style={{ marginTop: '16px' }}>
                            <Typography style={{ fontWeight: 'bold' }} fontSize={"16px"}>วันที่จัดส่ง</Typography>
                        </Grid>
                        <Grid xs={12} md={6} style={{ marginTop: '0px' }}>
                            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>
                                จากวันที่
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Controller
                                    name="posting_date_start"
                                    control={control}
                                    defaultValue={null} // ค่าเริ่มต้น
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            views={['day']}
                                            openTo="day"
                                            format="DD/MM/YYYY"
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                                            slotProps={{
                                                textField: { size: 'small', fullWidth: true },
                                                actionBar: {
                                                    actions: ['clear'],
                                                },
                                            }}
                                            required
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} md={6} style={{ marginTop: '0px' }}>
                            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>
                                ถึงวันที่
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Controller
                                    name="posting_date_end"
                                    control={control}
                                    defaultValue={null} // ค่าเริ่มต้น
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            views={['day']}
                                            openTo="day"
                                            format="DD/MM/YYYY"
                                            inputFormat="DD/MM/YYYY"
                                            renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                                            slotProps={{
                                                textField: { size: 'small', fullWidth: true },
                                                actionBar: {
                                                    actions: ['clear'],
                                                },
                                            }}
                                            required
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} md={6} style={{ marginTop: '10px' }}>
                            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>
                                RQ
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Controller
                                    name="is_rq_id"
                                    control={control}
                                    defaultValue={0} // ค่าเริ่มต้น
                                    render={({ field }) => (
                                        <Select
                                            id={"rq-select"}
                                            style={{ width: '100%' }}
                                            {...field}
                                            placeholder={'ทั้งหมด'}
                                            size="small"
                                        >
                                            <MenuItem style={{ opacity: 0.7 }} value={0}>
                                                ทั้งหมด
                                            </MenuItem>

                                            {
                                                masterData.rq.map((item, index) =>
                                                    <MenuItem key={index + item.name} value={item.id}>{item.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        {/*<Grid xs={6} style={{ marginTop: '10px' }}>*/}
                        {/*    <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>*/}
                        {/*        wsl status*/}
                        {/*    </Typography>*/}

                        {/*    <LocalizationProvider dateAdapter={AdapterDayjs} >*/}
                        {/*        <Controller*/}
                        {/*            name="wsl_id"*/}
                        {/*            control={control}*/}
                        {/*            defaultValue={0} // ค่าเริ่มต้น*/}
                        {/*            render={({ field }) => (*/}
                        {/*                <Select*/}
                        {/*                    id={'wsl-select'}*/}
                        {/*                    style={{ width: '100%' }}*/}
                        {/*                    {...field}*/}
                        {/*                    placeholder={'ทั้งหมด'}*/}
                        {/*                    size="small"*/}
                        {/*                    disabled*/}
                        {/*                >*/}
                        {/*                    <MenuItem style={{ opacity: 0.7 }} value={0}>*/}
                        {/*                        ทั้งหมด*/}
                        {/*                    </MenuItem>*/}

                        {/*                    {*/}
                        {/*                        masterData.wsl.map((item, index) =>*/}
                        {/*                            <MenuItem key={index + item.name} value={item.id}>{item.name}</MenuItem>*/}
                        {/*                        )*/}
                        {/*                    }*/}
                        {/*                </Select>*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*    </LocalizationProvider>*/}
                        {/*</Grid>*/}
                    </Grid>

                    <div className="dv-line opa-2" style={{ marginTop: '24px' }} />

                    <Grid xs={12} style={{ marginTop: '5px', marginBottom: '30px' }}>
                        <Stack spacing={2} justifyContent={'space-between'} alignItems={'center'} direction="row" sx={{ paddingTop: "5px" }}>
                            <Stack spacing={2} direction="row">
                                <CustomIOSSwitchComponent
                                    checked={isWsl}
                                    onChange={(event) => {
                                        const value = event.target.checked
                                        setIsWSL(value)
                                    }}
                                />
                                <div style={{ opacity: '0.8' }}>
                                    นำเข้า WSL
                                </div>
                            </Stack>

                            <Stack spacing={2} direction="row" >
                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    name="ExportWSL"
                                    loading={loadingWSL}
                                    loadingPosition="start"
                                    startIcon={<TextSnippetIcon style={{ marginRight: '5px' }}/>}
                                >
                                    Download WSL Format
                                </LoadingButton>

                                {/*<Button*/}
                                {/*    variant="contained"*/}
                                {/*    type="submit"*/}
                                {/*    name="ExportWSL"*/}
                                {/*    size={"large"}*/}
                                {/*>*/}
                                {/*    <TextSnippetIcon style={{ marginRight: '5px' }}/>*/}
                                {/*    Download WSL Format*/}
                                {/*</Button>*/}

                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    name="ExportReadable"
                                    loading={loadingReadable}
                                    loadingPosition="start"
                                    startIcon={<TextSnippetIcon style={{ marginRight: '5px' }}/>}
                                >
                                    Readable Format
                                </LoadingButton>

                                {/*<Button*/}
                                {/*    variant="contained"*/}
                                {/*    type="submit"*/}
                                {/*    name="ExportReadable"*/}
                                {/*    size={"large"}*/}
                                {/*    loading={true}*/}
                                {/*    loadingPosition="start"*/}
                                {/*>*/}
                                {/*    <TextSnippetIcon style={{ marginRight: '5px' }}/>*/}
                                {/*    Readable Format*/}
                                {/*</Button>*/}
                            </Stack>
                        </Stack>
                    </Grid>

                </Grid>
            </form>

        </>
    );

    function downloadCSVWithBOM(arrayBuffer, filename) {
        let blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), arrayBuffer]);

        let url = URL.createObjectURL(blob);

        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
    }

    function getFilename(response) {
        const contentDisposition =  response.headers["content-disposition"];
        let extractedFilename = 'default-filename';

        if (contentDisposition && contentDisposition.includes('filename=')) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch != null && filenameMatch[1]) {
                extractedFilename = filenameMatch[1].replace(/['"]/g, '');
                return extractedFilename
            }
        }
    }
}