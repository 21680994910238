import React, {Component, useState, useContext, useEffect, useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
// import FileDownload from "js-file-download";

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';


import {
    DataGrid,
    GridToolbar,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarFilterButton, GridToolbarExport
} from '@mui/x-data-grid';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import ApiShipmentImportFile from '../services/api/ApiShipmentImportFile';
import {AuthContext} from '../App.js';
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Swal from "sweetalert2";
import Loading from "./Loading";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {styled} from '@mui/material/styles';
import dayjs from "dayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import Autocomplete from "@mui/material/Autocomplete";
import {downloadFileFromResponse} from "../util/FileManager";
import {Stack} from "@mui/material";
import {Formatter} from "../util/Formatter";
import ImportFileComponent from "../components/ImportFileComponent";
import CustomDropZoneComponent from "../components/CustomDropZoneComponent";
import ManageColumnsDialog from "../dialog/ManageColumnsDialog";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import ApiAllocation from "../services/api/ApiAllocation";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabPanel from "../components/CustomTabPanel";
import IconPerformance from "../assets/images/ic/ic_performance.png"
import DvrIcon from '@mui/icons-material/Dvr';
import TrukrPerformanceDialog from "../dialog/TrukrPerformanceDialog";
import ApiImportFileXlsx from "../services/api/ApiImportFileXlsx";

const FileDownload = require('js-file-download');

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const NoMaxWidthTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

export default function ImportFileXlsx() {
    const trucker = [
        "Lotus - BBTDC",
        "Lotus - KKDC",
        "Lotus - LPRDC",
        "Lotus - SRRDC",
        "Lotus - WNDC",
        "Lotus - LLKDC",
        "Lotus - KKRDC"
    ]

    const {
        open,
        setOpen,
        userNPass,
        setUserNPass,
        isLoading,
        setIsLoading,
        isProfiles,
        setIsProfiles,
        isProgress,
        setIsProgress,
        profiles
    } = useContext(AuthContext);

    const truckrStatus = [
        {
            id: 1,
            name: "Tendered"
        },
        {
            id: 3,
            name: "In-Transit"
        },
        {
            id: 4,
            name: "Complete"
        },
        {
            id: 7,
            name: "Aborted"
        }
    ]

    const buTypeOptions = [
        {
            value: "LPRDC,LPRFDC",
            name: "LPRDC & LPRFDC"
        },
        {
            value: "LPRDC",
            name: "LPRDC"
        },
        {
            value: "LPRFDC",
            name: "LPRFDC"
        }
    ]

    const [file, setFile] = useState(null);
    const [fileSize, setFileSize] = useState(0);
    const [bus, setBus] = useState(profiles.roles[0] !== 'ROLE_SUPER_ADMIN' ? profiles.bu[0] : '');
    const [makro, setMakro] = useState('');
    const [cpAllSite, setCpAllSite] = useState('');
    const [buSiteCpAllCM, setBuSiteCpAllCM] = useState([]);
    const [buSiteCpAllNR, setBuSiteCpAllNR] = useState([]);
    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [drawer, setDrawer] = useState('none');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isApi, setIsApi] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [buTypeSelected, setBuTypeSelected] = useState("LPRDC,LPRFDC")

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [dataKey, setDataKey] = useState(0);
    const [columnVisibility, setColumnVisibility] = useState(
        JSON.parse(localStorage.getItem("column_import_visibility")) || {}
    );
    const [tapSelected, setTapSelected] = useState(3)
    const isLotusUpdate = (tapSelected === 1)

    const columns = [
        {
            field: 'no',
            headerName: 'ลำดับ',
            width: 60,
            editable: false,
            align: 'center',
            renderCell: (params) => params.no
        },
        {
            field: '-',
            headerClassName: 'super-app-theme--header',
            headerName: '',
            width: 60,
            sortable: false,
            align: 'center',
            renderCell: (params) => params.row.isShowTrukrPerformance ? <img
                style={{ width: '26px', cursor: 'pointer' }}
                src={IconPerformance}
                alt="IconPerformance"
                onClick={async () => {
                    if (params.row.id) {
                        // TODO: validate isShowTrukrPerformance = true ถึงจะ show icon
                        setIsLoading(true)
                        const res = await ApiImportFileXlsx.getTrukrPerformance(params.row.id)
                        setIsLoading(false)

                        if (res.status === 200 && res.data) {
                            TrukrPerformanceDialog(
                                "Performance",
                                "ดูข้อมูลประสิทธิภาพ",
                                res.data,
                                truckrStatus
                            )
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: res.toLocaleString(),
                                icon: 'error',
                                showConfirmButton: false,
                            }).then(() => {})
                        }
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: "File result id not found.",
                            icon: 'error',
                            showConfirmButton: false,
                        }).then(() => {})
                    }
                }}
            /> : null
        },
        {
            field: 'importedData',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันที่อัพโหลด',
            minWidth: 180,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'fileName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อไฟล์',
            minWidth: 350,
            flex: 5,
            sortable: false,
            align: 'left',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    params.row.fileName?.includes("API") ?
                        <div className={'opa-6'} style={{ cursor: 'default' }}>{params.row.fileName}</div> :
                        <NoMaxWidthTooltip
                            arrow
                            placement="top"
                            title={'ดาวน์โหลดไฟล์: ' + params.row.fileName}
                            TransitionComponent={Zoom}
                        >
                            <Typography
                                sx={{ width: '100%', textAlign: 'left' }}
                                noWrap
                                className={'link-default'}
                                onClick={async () => {
                                    setIsLoading(true)
                                    let res = await ApiShipmentImportFile.downloadLog({
                                        fileName: params.row.generatedFileName,
                                        bu: params.row.buType
                                    });
                                    if (res.status !== 200) {
                                        setIsLoading(false)
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        })
                                    } else {
                                        FileDownload(res.data, params.row.fileName);
                                    }

                                    setIsLoading(false)
                                }}
                            >
                                {params.row.fileName}
                            </Typography>
                        </NoMaxWidthTooltip>
                )
            }
        },
        {
            field: 'buType',
            headerClassName: 'super-app-theme--header',
            headerName: 'ลูกค้า',
            minWidth: 220,
            flex: 2,
            sortable: false,
            align: 'left',
            headerAlign: 'center',
        },
        {
            field: 'totalRecords',
            headerClassName: 'super-app-theme--header',
            headerName: 'รวม',
            minWidth: 80,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'result',
            headerClassName: 'super-app-theme--header',
            headerName: 'ผลลัพธ์',
            minWidth: 110,
            maxWidth: 150,
            flex: 5,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.status !== 'Waiting' && params.row.status !== 'Fail') {
                     if (params.row.successRecords && params.row.failedRecords) {
                         return (
                             <Grid
                                 fullWidth
                                 container
                                 direction="column"
                                 justifyContent="center"
                                 alignContent="center"
                                 sx={{
                                     px: '10px',
                                     py: isShowView(params.row.failedRecords) && isShowView(params.row.successRecords) ? '5px' : '16px',
                                     fontSize: isShowView(params.row.failedRecords) && isShowView(params.row.successRecords) ? '15.4px' : '15.6px'
                                }}
                             >
                                 {
                                     isShowView(params.row.successRecords) ?
                                         <Stack
                                             direction="row"
                                             sx={{justifyContent: "space-between", alignItems: "center", width: '110px', mb: isShowView(params.row.failedRecords) ? '6px' : '0px' }}
                                         >
                                             <div className={'opa-6'}>สำเร็จ</div><div style={{color: '#69C361'}}>{params.row.successRecords}</div>
                                         </Stack> : null
                                 }

                                 {
                                     isShowView(params.row.failedRecords) ?
                                         <Stack
                                             direction="row"
                                             sx={{justifyContent: "space-between", alignItems: "center", width: '110px'}}
                                         >
                                             <div className={'opa-6'}>ไม่สำเร็จ</div><div style={{color: '#ED1C24'}}>{params.row.failedRecords}</div>
                                         </Stack> : null
                                 }
                             </Grid>
                         )
                    }

                    return (
                        <Grid container direction="row" justifyContent="center">
                        </Grid>
                    )
                }
            }
        },
        {
            field: 'failBuTracking',
            headerClassName: 'super-app-theme--header',
            headerName: 'Import results',
            minWidth: 150,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.status !== 'Waiting') {
                    return (
                        <>
                            <Button
                                disabled={isProgress}
                                variant="contained"
                                disableElevation
                                size={"small"}
                                sx={{
                                    backgroundColor: '#6F9EE8',
                                    color: '#ffffff',
                                    ':hover': {
                                        backgroundColor: '#4380E1',
                                        color: 'white',
                                    },
                                }}
                                onClick={async () => {
                                    setIsProgress(true)
                                    let res = await ApiShipmentImportFile.downloadImportFileResult({id: params.row.id})
                                    if (res.status !== 200) {
                                        setIsLoading(false)
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        })
                                    } else {
                                        FileDownload(res.data, 'Import_result_' + params.row.fileName);
                                    }
                                    setIsProgress(false)
                                }}
                            >
                                Download
                            </Button>
                        </>
                    )
                } else {
                    return (
                        <></>
                    )
                }
                /*if (params.row.buType === 'Makro' && params.row.status != 'Waiting'){
                    return (
                        <>
                            <Button
                                disabled={isProgress}
                                variant="contained"
                                disableElevation
                                size={"small"}
                                sx={{
                                    backgroundColor: '#6F9EE8',
                                    color: '#ffffff',
                                    ':hover': {
                                        backgroundColor: '#4380E1',
                                        color: 'white',
                                    },
                                }}
                                onClick={ async ()=>{
                                    setIsProgress(true)
                                    let res = await ApiShipmentImportFile.downloadImportFileResult({id:params.row.id})
                                    if (res.status !== 200){
                                        setIsLoading(false)
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        })
                                    } else {
                                        FileDownload(res.data, 'Import_result_'+params.row.fileName);
                                    }
                                    setIsProgress(false)
                                }}
                            >
                                Download
                            </Button>
                        </>
                    )
                } else if (params.row.failBuTracking === 1) {
                    return  (
                        <IconButton
                            sx={{p:0, m:0}}
                            onClick={ async () => {
                                let spiltData = (params.row.errorText).split(",");
                                let alertData = [];
                                let showData = spiltData.map((mss) => {
                                    if (mss) {
                                        alertData.push('- ' + mss);
                                    }
                                });
                                setAlertData(alertData);
                                await handleClickOpen();
                            }}
                        >
                            <ErrorOutlineSharpIcon color="error" />
                        </IconButton>
                    )
                } else {
                    return (
                        <></>
                    )
                }*/
            }
        },
        {
            field: 'action',
            headerClassName: 'super-app-theme--header',
            headerName: 'Action',
            minWidth: 90,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'createdBy',
            headerClassName: 'super-app-theme--header',
            headerName: 'สร้างโดย',
            minWidth: 220,
            flex: 2,
            sortable: true,
            align: 'left',
            headerAlign: 'center',
        },
        /*{
            field: 'refresh',
            headerClassName: 'super-app-theme--header',
            headerName: 'รีเฟรช',
            minWidth: 85,
            flex: 1,
            sortable: false,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.status !== 'Done' && params.row.status !== 'Fail' ){
                    return (
                        <IconButton
                            sx={{p:0, m:0}}
                            onClick={async ()=>{
                                setIsLoading(true)
                                setIsProgress(true)
                                // await delay(2000);
                                // let resCreate = await ApiSubscription.create();
                                // if (resCreate.status === 200){
                                //     console.log(resCreate)
                                //     alert(JSON.stringify(resCreate.data))
                                // }
                                await fetchData().catch((e)=>{
                                    console.log(e)
                                    setIsLoading(false)
                                    setIsProgress(false)
                                })
                                console.log(isProfiles)
                                setIsLoading(false)
                                setIsProgress(false)
                            }}
                        >
                            <RefreshIcon/>
                        </IconButton>
                    )
                }
                return (
                    <></>
                )}
        },*/
    ];

    const [status, setStatus] = useState({
        debrief: false,
        buType: "LPRDC,LPRFDC",
        truckrStatus: [{
            id: 1,
            name: "Tendered"
        }],
    })

    const [search, setSearch] = useState({
        submitDate: dayjs(Date.now()),
        start_date: null,
        min_start_date: dayjs(Date.now()),
        max_start_date: dayjs(Date.now()),
        fileName: '',
        status: "ทั้งหมด",
    });

    const [reqUpdate, setReqUpdate] = useState({
        tripNo: "",
        submitDate: dayjs(Date.now()),
        start_date: 1,
        min_start_date: dayjs(Date.now()),
        max_start_date: dayjs(Date.now()),
        buType: "LPRDC,LPRFDC",
        debrief: false,
        truckrStatus: [
            {
                id: 1,
                name: "Tendered"
            },
            {
                id: 3,
                name: "In-Transit"
            },
            {
                id: 4,
                name: "Complete"
            }
        ],
    })

    const afterApi = async (res) => {
        if (res.status !== 200) {
            setIsLoading(false)
            setIsProfiles(false)
            setUserNPass(null);
            await localStorage.setItem('userNPass', JSON.stringify(null));
        } else {
            console.log('====== Debug api success')
        }
    }

    useEffect(() => {
        async function inti() {
            await fetchData().catch((e) => {
                setIsLoading(false)
                setIsProgress(false)
            })
        }

        inti().catch(console.error)
    }, []);

    const fetchData = async () => {
        setIsLoading(true)
        setIsProgress(true)
        let res = await ApiShipmentImportFile.search({
            date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            min_start_date: search.submitDate ? new Date(search.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            max_start_date: search.submitDate ? new Date(search.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            fileName: search.fileName,
            status: search.status,
            customerName: bus,
            page: page,
            limit: rowsPerPage,
            isAPI: isApi
        });

        if (res.status === 200) {
            console.log(res)
            setRows(res.data.listData)
            setBuSiteCpAllCM(res.data.buSiteCpAllCM)
            setBuSiteCpAllNR(res.data.buSiteCpAllNR)
        }
        setIsLoading(false)
        setIsProgress(false)
    };

    const DrawerBtn = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (drawerOpenBtn === false) {
            setDrawer('block')
        }
        if (drawerOpenBtn === true) {
            setDrawer('none')
        }
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    const testProgress = async () => {
        setIsProgress(true)
        await delay(2000);
        setIsProgress(false)
    };
    const testLoading = async () => {
        setIsLoading(true)
        setIsProgress(true)
        await delay(2000);
        setIsLoading(false)
        setIsProgress(false)
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertData, setAlertData] = useState([]);
    const handleClickOpen = async () => {
        setAlertOpen(true);
    };
    const handleClose = () => {
        setAlertOpen(false);
        setAlertData([]);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer
                key={"custom-toolbar-orders-table"}
            >
                <Button
                    size={'small'}
                    onClick={() => {
                        ManageColumnsDialog(
                            "จัดการคอลัมน์",
                            columns,
                            getColumns(columns, JSON.parse(localStorage.getItem("column_import_position"))),
                            columnVisibility,
                            (value) => {
                                setColumnVisibility(value)
                                localStorage.setItem("column_import_visibility", JSON.stringify(value))
                            },
                            (value) => {
                                localStorage.setItem("column_import_position", JSON.stringify(value))
                                setDataKey(prevKey => prevKey + 1); // Update key when rows change
                            }
                        )
                    }}
                    color="primary"
                    startIcon={<ViewWeekIcon />}
                    sx={{ textTransform: 'none' }} // Optional: Disable uppercase text transformation
                >
                    Manage Columns
                </Button>
            </GridToolbarContainer>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            {
                !isProfiles
                    ? <Loading/>
                    :
                    <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, overflow: 'auto', pb: '30px', pr: '6px', pl: '6px'}}>
                        <Grid container>
                            <Grid xs={11} item>
                                <Typography variant="h5" sx={{pl: '8px', pb: '15px', fontSize: '24px', fontWeight: 800}}>
                                    Import File Page
                                </Typography>
                            </Grid>
                            <Grid container item xs={1} sx={{display: {xs: 'block', sm: 'none'},}} direction="row"
                                  alignItems="end">
                                <IconButton aria-label="delete" onClick={DrawerBtn}>
                                    {drawerOpenBtn ? <ExpandMore/> : <ExpandLess/>}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{mb: '10px'}}/>
                        <Grid container spacing={2}
                              sx={{pt: 1, pl: {xs: 0, md: 1}, display: {xs: drawer, sm: 'block'},}}>
                            <Grid container item>
                                <Grid container item spacing={2} sx={{pb: '20px'}} direction="row" alignItems="center"
                                      justifyContent="start">
                                    <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                        <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                            BU:
                                        </Typography>
                                        <Select
                                            fullWidth
                                            sx={{mt: '8px'}}
                                            value={bus}
                                            size="small"
                                            onChange={(e) => {
                                                setBus(e.target.value)
                                                setMakro('')
                                                setCpAllSite('')
                                                setIsApi(false)
                                                setTapSelected(3)
                                            }}
                                        >
                                            {profiles.bu.map((m) => {
                                                return <MenuItem value={m}>{m}</MenuItem>
                                            })}
                                        </Select>
                                    </Grid>
                                    {
                                        bus === 'Makro' && !isNew
                                            ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                    สาขา:
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    sx={{mt: '8px'}}
                                                    value={makro}
                                                    size="small"
                                                    onChange={(e) => {
                                                        setMakro(e.target.value)
                                                    }}
                                                >
                                                    <MenuItem value={'บางนา'}>{'บางนา'}</MenuItem>
                                                    <MenuItem value={'วังน้อย'}>{'วังน้อย'}</MenuItem>
                                                    <MenuItem value={'วังน้อย2'}>{'วังน้อย2'}</MenuItem>
                                                </Select>
                                            </Grid>
                                            :
                                            <></>
                                    }

                                    {
                                        trucker.includes(bus) ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column" alignItem="end">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        checked={isApi}
                                                        style={{ marginRight: '25px', marginTop: '35px' }}
                                                        control={<Checkbox />}
                                                        label="ระบบ Trukr"
                                                        onChange={(event) => {
                                                            setIsApi(event.target.checked);
                                                            setTapSelected(event.target.checked ? 0 : 3)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Grid> : null
                                    }

                                    {
                                        bus === 'Makro' ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column" alignItem="end">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        checked={isNew}
                                                        style={{ marginRight: '25px', marginTop: '35px' }}
                                                        control={<Checkbox />}
                                                        label="New Template"
                                                        onChange={(event) => {
                                                            setIsNew(event.target.checked);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Grid> : null
                                    }

                                    {
                                        bus === 'CPALL เชียงใหม่' || bus === 'CPALL นครราชสีมา'
                                            ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                    สาขา:
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    sx={{mt: '8px'}}
                                                    value={cpAllSite}
                                                    size="small"
                                                    onChange={(e) => {
                                                        setCpAllSite(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        bus === 'CPALL เชียงใหม่'
                                                            ?
                                                            buSiteCpAllCM.map((site) => {
                                                                return <MenuItem value={site}>{site}</MenuItem>
                                                            })
                                                            :
                                                            buSiteCpAllNR.map((site) => {
                                                                return <MenuItem value={site}>{site}</MenuItem>
                                                            })
                                                    }
                                                </Select>
                                            </Grid>
                                            :
                                            <></>
                                    }
                                </Grid>

                                <div style={{ width: '100%' }}>
                                    {
                                        isApi &&
                                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                                <Tabs
                                                    value={tapSelected}
                                                    onChange={(event, newValue) => {
                                                        setTapSelected(newValue)
                                                    }}
                                                    aria-label="basic tabs example"
                                                >
                                                    <Tab label="Create" {...a11yProps(1)} />
                                                    <Tab label="Update" {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>
                                    }

                                    <CustomTabPanel value={tapSelected} index={0}>
                                        <Grid container spacing={2} sx={{py: '20px'}} item>
                                            <Grid xs={12} sm={3} md={3} lg={2} item>
                                                <Typography variant="subtitle1">
                                                    Min start date:
                                                </Typography>
                                                <TextField
                                                    value={search.start_date}
                                                    fullWidth
                                                    type="number"
                                                    size={'small'}
                                                    inputProps={{ min: 0, max: 10, width: '50px' }}
                                                    min={0}
                                                    max={100}
                                                    onChange={(e) => {
                                                        setSearch(search => ({...search, ...{start_date: e.target.value}}))
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} md={4} lg={4} item>
                                                <Typography variant="subtitle1">
                                                    Update date from:
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <DatePicker
                                                            sx={{ pr: '10px' }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            value={search.min_start_date}
                                                            onChange={(newValue) => {
                                                                setSearch(search => ({...search, ...{min_start_date: newValue}}))
                                                            }}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    actions: ['clear'],
                                                                },
                                                            }}
                                                            renderInput={(params) => <TextField {...params} fullWidth/>}
                                                        />
                                                        <TimePicker
                                                            label=""
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            value={search.min_start_date}
                                                            onChange={(newValue) => {
                                                                setSearch(search => ({...search, ...{min_start_date: newValue}}))
                                                            }}
                                                        />
                                                    </div>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={6} lg={4} item>
                                                <Typography variant="subtitle1">
                                                    Update date to:
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <DatePicker
                                                            sx={{ pr: '10px' }}
                                                            value={search.max_start_date}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            onChange={(newValue) => {
                                                                setSearch(search => ({...search, ...{max_start_date: newValue}}))
                                                            }}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    actions: ['clear'],
                                                                },
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        <TimePicker
                                                            label=""
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            value={search.max_start_date}
                                                            onChange={(newValue) => {
                                                                setSearch(search => ({...search, ...{max_start_date: newValue}}))
                                                            }}
                                                        />
                                                    </div>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid container xs={12} sm={6} md={6} lg={6} item direction="column">
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <div style={{ paddingRight: '10px', width: '100%' }}>
                                                        <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                            Truckr status:
                                                        </Typography>
                                                        <Autocomplete
                                                            size="small"
                                                            multiple
                                                            fullWidth
                                                            onChange={(e, array) => {
                                                                const idSet = new Set();
                                                                const hasDuplicates = array.some(item => {
                                                                    if (idSet.has(item.id)) {
                                                                        return true; // Duplicate found
                                                                    }
                                                                    idSet.add(item.id);
                                                                    return false;
                                                                });

                                                                if (!hasDuplicates) {
                                                                    setStatus(prevState => ({...prevState, truckrStatus: array}))
                                                                }
                                                            }}
                                                            getOptionLabel={(option) => option.name}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            options={truckrStatus}
                                                            value={status.truckrStatus}
                                                            clearOnEscape
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    size={'small'}
                                                                    sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0},}}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                    }}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {/*{paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}*/}
                                                                                {params.InputProps.endAdornment}
                                                                            </>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}

                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                            Debrief:
                                                        </Typography>
                                                        <Select
                                                            fullWidth
                                                            value={status.debrief}
                                                            size="small"
                                                            onChange={(e) => {
                                                                setStatus(prevState => ({...prevState, debrief: e.target.value}))
                                                            }}
                                                        >
                                                            <MenuItem value={true}>true</MenuItem>
                                                            <MenuItem value={false}>false</MenuItem>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                <div>
                                                    <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                        Bu Type:
                                                    </Typography>
                                                    <Select
                                                        fullWidth
                                                        value={status.buType}
                                                        size="small"
                                                        onChange={(e) => {
                                                            setStatus(prevState => ({...prevState, buType: e.target.value}))
                                                        }}
                                                    >
                                                        {
                                                            buTypeOptions.map(option =>
                                                                <MenuItem value={option.value}>{option.name}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={tapSelected} index={1}>
                                        <Grid container spacing={2} sx={{py: '20px'}} item>
                                            <Grid xs={12} sm={3} md={3} lg={2} item>
                                                <Typography variant="subtitle1">
                                                    Min start date:
                                                </Typography>
                                                <TextField
                                                    value={reqUpdate.start_date}
                                                    fullWidth
                                                    type="number"
                                                    size={'small'}
                                                    inputProps={{ min: 0, max: 10, width: '50px' }}
                                                    min={0}
                                                    max={100}
                                                    onChange={(e) => {
                                                        setReqUpdate(req => ({...req, ...{start_date: e.target.value}}))
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} md={4} lg={4} item>
                                                <Typography variant="subtitle1">
                                                    Update date from:
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <DatePicker
                                                            sx={{ pr: '10px' }}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            value={reqUpdate.min_start_date}
                                                            onChange={(newValue) => {
                                                                setReqUpdate(req => ({...req, ...{min_start_date: newValue}}))
                                                            }}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    actions: ['clear'],
                                                                },
                                                            }}
                                                            renderInput={(params) => <TextField {...params} fullWidth/>}
                                                        />
                                                        <TimePicker
                                                            label=""
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            value={reqUpdate.min_start_date}
                                                            onChange={(newValue) => {
                                                                setReqUpdate(req => ({...req, ...{min_start_date: newValue}}))
                                                            }}
                                                        />
                                                    </div>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={6} lg={4} item>
                                                <Typography variant="subtitle1">
                                                    Update date to:
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <DatePicker
                                                            sx={{ pr: '10px' }}
                                                            value={reqUpdate.max_start_date}
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            onChange={(newValue) => {
                                                                setReqUpdate(req => ({...req, ...{max_start_date: newValue}}))
                                                            }}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    actions: ['clear'],
                                                                },
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        <TimePicker
                                                            label=""
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            value={reqUpdate.max_start_date}
                                                            onChange={(newValue) => {
                                                                setReqUpdate(req => ({...req, ...{max_start_date: newValue}}))
                                                            }}
                                                        />
                                                    </div>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid container xs={12} sm={6} md={6} lg={6} item direction="column">
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <div style={{ paddingRight: '10px', width: '100%' }}>
                                                        <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                            Truckr status:
                                                        </Typography>
                                                        <Autocomplete
                                                            size="small"
                                                            multiple
                                                            fullWidth
                                                            onChange={(e, array) => {
                                                                const idSet = new Set();
                                                                const hasDuplicates = array.some(item => {
                                                                    if (idSet.has(item.id)) {
                                                                        return true; // Duplicate found
                                                                    }
                                                                    idSet.add(item.id);
                                                                    return false;
                                                                });

                                                                if (!hasDuplicates) {
                                                                    setReqUpdate(req => ({...req, truckrStatus: array}))
                                                                }
                                                            }}
                                                            getOptionLabel={(option) => option.name}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            options={truckrStatus}
                                                            value={reqUpdate.truckrStatus}
                                                            clearOnEscape
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    size={'small'}
                                                                    sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0},}}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                    }}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {/*{paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}*/}
                                                                                {params.InputProps.endAdornment}
                                                                            </>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}

                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                            Debrief:
                                                        </Typography>
                                                        <Select
                                                            fullWidth
                                                            value={reqUpdate.debrief}
                                                            size="small"
                                                            onChange={(e) => {
                                                                setReqUpdate(req => ({...req, debrief: e.target.value}))
                                                            }}
                                                        >
                                                            <MenuItem value={true}>true</MenuItem>
                                                            <MenuItem value={false}>false</MenuItem>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                <div>
                                                    <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                        Bu Type:
                                                    </Typography>
                                                    <Select
                                                        fullWidth
                                                        value={reqUpdate.buType}
                                                        size="small"
                                                        onChange={(e) => {
                                                            setReqUpdate(req => ({...req, buType: e.target.value}))
                                                        }}
                                                    >
                                                        {
                                                            buTypeOptions.map(option =>
                                                                <MenuItem value={option.value}>{option.name}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <TextField
                                            value={reqUpdate.tripNo}
                                            sx={{ mt: 3, mb: 3 }}
                                            fullWidth
                                            label="Trip No."
                                            multiline
                                            maxRows={4}
                                            size={"small"}
                                            onChange={(event) => {
                                                setReqUpdate(req => ({...req, tripNo: event.target.value}))
                                            }}
                                        />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={tapSelected} index={3}>
                                        <div style={{width: '100%', marginBottom: '20px'}}>
                                            <CustomDropZoneComponent
                                                file={file}
                                                onChange={(file) => {
                                                    if (file) {
                                                        setFile(file)
                                                    } else {
                                                        setFile(null)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </CustomTabPanel>
                                </div>

                                <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
                                    {
                                        checkingTemplateButton(bus, isNew) === true ?
                                            <Button
                                                disabled={isProgress}
                                                variant="contained"
                                                disableElevation
                                                onClick={async ()=> {
                                                    setIsLoading(true)

                                                    const request = {
                                                        buName: bus
                                                    }

                                                    let res = await ApiShipmentImportFile.downloadTemp(request)
                                                    if (res.data) {
                                                        downloadFileFromResponse(res)

                                                        Swal.fire({
                                                            title: 'Download Template Success',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                        }).then(() => {})
                                                    } else {
                                                        Swal.fire({
                                                            title: 'Download Template Fail',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                        }).then(() => {})
                                                    }

                                                    setIsLoading(false)
                                                }}
                                                sx={{
                                                    mr: '5px',
                                                    backgroundColor: '#4895ef',
                                                    color: '#ffffff',
                                                    width: '180px',
                                                    ':hover': {
                                                        backgroundColor: '#1a61b5',
                                                        color: 'white',
                                                    },
                                                }}
                                            >
                                                Download template
                                            </Button> : <></>
                                    }

                                    {
                                        checkingUpdateButton(bus, isApi, isLotusUpdate) === true ?
                                            <Button
                                                disabled={isProgress}
                                                variant="contained"
                                                disableElevation
                                                onClick={async () => {
                                                    if (isApi) {
                                                        const tripNo = reqUpdate.tripNo.replaceAll(" ", "")
                                                        const tripNoArr = tripNo?.split(",")
                                                        const validateMessage = validateTripNo(tripNo)
                                                        if (validateMessage) {
                                                            Swal.fire({
                                                                title: "ไม่สำเร็จ",
                                                                text: validateMessage,
                                                                icon: "error",
                                                                showConfirmButton: false,
                                                            });
                                                        } else {
                                                            setIsLoading(true)

                                                            const dataImport = {
                                                                bu: bus,
                                                                userId: JSON.parse(localStorage.getItem("profile")).userId,
                                                                action: "Update",
                                                                isAPI: isApi,
                                                                truckrStatus: getTruckrStatus(reqUpdate.truckrStatus),
                                                                debrief: reqUpdate.debrief,
                                                                start_date: reqUpdate.start_date,
                                                                min_start_date: reqUpdate.min_start_date ? new Date(reqUpdate.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                                max_start_date: reqUpdate.max_start_date ? new Date(reqUpdate.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                                updateBuTrackingList: tripNoArr,
                                                                buType: reqUpdate.buType
                                                            };

                                                            let resImportFileXlsx = await ApiShipmentImportFile.lotusUpdate(dataImport)

                                                            if (resImportFileXlsx.status !== 200) {
                                                                Swal.fire({
                                                                    title: 'ไม่สำเร็จ',
                                                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                    icon: 'error',
                                                                    showConfirmButton: false,
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    title: "สำเร็จ",
                                                                    text: "แก้ไขข้อมูลเที่ยววิ่งสำเร็จ",
                                                                    icon: "success",
                                                                    showConfirmButton: false
                                                                }).then(async () => {
                                                                    setFile(null)

                                                                    await fetchData().catch((e) => {
                                                                        console.log(e)
                                                                        setIsLoading(false)
                                                                        setIsProgress(false)
                                                                    })
                                                                })
                                                            }

                                                            // await afterApi(resImportFileXlsx);
                                                            setIsLoading(false)
                                                        }
                                                    }
                                                    else {
                                                        if (!file && !(isApi && trucker.includes(bus))) {
                                                            Swal.fire({
                                                                title: 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        } else if (bus === null) {
                                                            Swal.fire({
                                                                title: 'กรุณาระบุเลือก BU',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        } else if (bus === 'Makro' && makro === '' && isNew === false) {
                                                            Swal.fire({
                                                                title: 'กรุณาระบุเลือกสาขา',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        } else {
                                                            setIsLoading(true)

                                                            const dataImport = {
                                                                file: file,
                                                                bu: bus,
                                                                makro: makro,
                                                                cpall: cpAllSite,
                                                                userId: JSON.parse(localStorage.getItem("profile")).userId,
                                                                action: "Update",
                                                                isAPI: isApi,
                                                                isNew: isNew,
                                                                buType: status.buType,
                                                                truckrStatus: getTruckrStatus(status.truckrStatus),
                                                                debrief: status.debrief,
                                                                start_date: search.start_date,
                                                                min_start_date: search.min_start_date ? new Date(search.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                                max_start_date: search.max_start_date ? new Date(search.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null
                                                            };

                                                            let resImportFileXlsx = await ApiShipmentImportFile.importFile(dataImport)
                                                            // console.log(resImportFileXlsx)
                                                            if (resImportFileXlsx.status !== 200) {
                                                                Swal.fire({
                                                                    title: 'อัพโหลดไม่สำเร็จ',
                                                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                    icon: 'error',
                                                                    showConfirmButton: false,
                                                                })
                                                            } else {
                                                                // console.log(resImportFileXlsx.data)
                                                                Swal.fire({
                                                                    title: 'อัพโหลดสำเร็จ',
                                                                    icon: 'success',
                                                                    showConfirmButton: false,
                                                                }).then(async () => {
                                                                    setFile(null)

                                                                    await fetchData().catch((e) => {
                                                                        console.log(e)
                                                                        setIsLoading(false)
                                                                        setIsProgress(false)
                                                                    })
                                                                })
                                                            }

                                                            // await afterApi(resImportFileXlsx);
                                                            setIsLoading(false)
                                                        }
                                                    }
                                                }}
                                                sx={{
                                                    mr: '5px',
                                                    backgroundColor: '#e8de6f',
                                                    color: '#ffffff',
                                                    width: '100px',
                                                    ':hover': {
                                                        backgroundColor: '#dce143',
                                                        color: 'white',
                                                    },
                                                }}
                                            >
                                                Update
                                            </Button> : <></>
                                    }

                                    {
                                        checkingImportButton(isApi, isLotusUpdate) &&
                                        <Button
                                            disabled={isProgress}
                                            variant="contained"
                                            disableElevationf
                                            onClick={async () => {
                                                console.log("====== Debug selected file: ", file)

                                                if (!file && !(isApi && trucker.includes(bus))) {
                                                    Swal.fire({
                                                        title: 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด',
                                                        icon: 'error',
                                                        showConfirmButton: false,
                                                    })
                                                } else if (bus === '') {
                                                    Swal.fire({
                                                        title: 'กรุณาระบุเลือก BU',
                                                        icon: 'error',
                                                        showConfirmButton: false,
                                                    })
                                                } else if (bus === 'Makro' && makro === '' && isNew === false) {
                                                    Swal.fire({
                                                        title: 'กรุณาระบุเลือกสาขา',
                                                        icon: 'error',
                                                        showConfirmButton: false,
                                                    })
                                                } else if ((bus === 'CPALL เชียงใหม่' || bus === 'CPALL นครราชสีมา') && cpAllSite === '') {
                                                    Swal.fire({
                                                        title: 'กรุณาระบุเลือกสาขา',
                                                        icon: 'error',
                                                        showConfirmButton: false,
                                                    })
                                                } else {
                                                    setIsLoading(true)

                                                    // Makro old template FH/FZ to zynx
                                                    if (
                                                        bus === "Makro Fresh - Wangnoi" ||
                                                        bus === "Makro Fresh - Mahachai" ||
                                                        bus === "Makro Frozen - Wangnoi" ||
                                                        bus === "Makro Frozen - Mahachai" ||
                                                        bus === "Makro Transfer"
                                                    ) {
                                                        const request = new FormData();
                                                        request.append('file', file);
                                                        request.append('bu', bus);
                                                        request.append('userId', JSON.parse(localStorage.getItem("profile")).userId);
                                                        request.append('action', "Import");

                                                        let response = await ApiShipmentImportFile.importFileToSynx(request)
                                                        setIsLoading(false)

                                                        if (response.status === 200) {
                                                            Swal.fire({
                                                                title: 'อัพโหลดสำเร็จ',
                                                                icon: 'success',
                                                                showConfirmButton: false,
                                                            }).then(async () => {
                                                                setFile(null)
                                                                setIsLoading(true)
                                                                console.log("Debug response import file => ", response.data.entry)

                                                                if (response.data?.entry) {
                                                                    let res = await ApiShipmentImportFile.autoApprove(response.data?.entry)
                                                                    console.log("Debug response auto approve => ", res)
                                                                }

                                                                await fetchData().catch((e) => {
                                                                    console.log(e)
                                                                    setIsLoading(false)
                                                                    setIsProgress(false)
                                                                })
                                                            })
                                                        } else {
                                                            Swal.fire({
                                                                title: 'อัพโหลดไม่สำเร็จ',
                                                                text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        }
                                                    } else {
                                                        const dataImport = {
                                                            file: file,
                                                            bu: bus,
                                                            makro: makro,
                                                            cpall: cpAllSite,
                                                            userId: JSON.parse(localStorage.getItem("profile")).userId,
                                                            action: "Import",
                                                            isAPI: isApi,
                                                            isNew: isNew,
                                                            truckrStatus: getTruckrStatus(status.truckrStatus),
                                                            buType: status.buType,
                                                            debrief: status.debrief,
                                                            start_date: search.start_date,
                                                            min_start_date: search.min_start_date ? new Date(search.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            max_start_date: search.max_start_date ? new Date(search.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null
                                                        };

                                                        let resImportFileXlsx = await ApiShipmentImportFile.importFile(dataImport)
                                                        if (resImportFileXlsx.status !== 200) {
                                                            // console.log("===== Debug data error: ", resImportFileXlsx)

                                                            const error = resImportFileXlsx.response.data.error
                                                            let content = ""

                                                            if (error) {
                                                                content += "<div style='width: 100%; text-align: left; max-height: 300px; overflow-x: hidden'>"

                                                                error.forEach(data => {
                                                                    if (data.index) {
                                                                        content += "<div style='margin-bottom: 5px; font-weight: bold'>" + (data.index) + "</div>"
                                                                    }

                                                                    data.error.forEach(message => {
                                                                        content += (
                                                                            data.index ?
                                                                                "<div style='font-size: 15px; margin-left: 8px'>- " + message + "</div>" :
                                                                                (
                                                                                    (data.error?.length ?? 0) > 1 ?
                                                                                        "<div style='font-size: 18px; margin-left: 8px'>- " + message + "</div>" :
                                                                                        "<div style='font-size: 18px; width: 100%; text-align: center'>" + message + "</div>"
                                                                                )
                                                                        )
                                                                    })
                                                                })

                                                                content += "</div>"
                                                            } else {
                                                                content += "<div>กรุณาทำรายการใหม่อีกครั้ง</div>"
                                                            }

                                                            Swal.fire({
                                                                title: 'อัพโหลดไม่สำเร็จ',
                                                                html: content,
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        } else {
                                                            Swal.fire({
                                                                title: 'อัพโหลดสำเร็จ',
                                                                icon: 'success',
                                                                showConfirmButton: false,
                                                            }).then(async () => {
                                                                setFile(null)
                                                                setIsLoading(true)

                                                                // console.log("===== Debug data resImportFileXlsx: ", resImportFileXlsx)
                                                                // if (
                                                                //     resImportFileXlsx.data?.entry &&
                                                                //     bus.includes("Makro")
                                                                // ) {
                                                                //     let res = await ApiShipmentImportFile.autoApprove(resImportFileXlsx.data?.entry)
                                                                //     console.log("Debug response auto approve => ", res)
                                                                // }

                                                                await fetchData().catch((e) => {
                                                                    // console.log(e)
                                                                    setIsLoading(false)
                                                                    setIsProgress(false)
                                                                })
                                                            })
                                                        }
                                                    }
                                                }

                                                // await afterApi(resImportFileXlsx);
                                                setIsLoading(false)
                                            }}
                                            sx={{
                                                // fontWeight: 'bold',
                                                backgroundColor: '#6F9EE8',
                                                color: '#ffffff',
                                                width: '100px',
                                                ':hover': {
                                                    backgroundColor: '#4380E1',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            Import
                                        </Button>
                                    }
                                </Grid>
                            </Grid>

                            <Divider sx={{mt: '20px'}}/>

                            <Grid container spacing={2} item>
                                {/*search*/}
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        วันที่อัพโหลด:
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                slotProps={{ textField: { size: 'small' } }}
                                                value={search.submitDate}
                                                // label={'DD/MM/YY'}
                                                // views={['day', 'month', 'year']}
                                                onChange={(newValue) => {
                                                    setSearch(search => ({...search, ...{submitDate: newValue}}))
                                                    // console.log(newValue)
                                                }}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ['clear'],
                                                    },
                                                }}
                                                renderInput={(params) =>
                                                    <TextField {...params} fullWidth sx={{py: '3px'}}/>
                                                }
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        ชื่อไฟล์:
                                    </Typography>
                                    <TextField
                                        size={'small'}
                                        value={search.fileName}
                                        fullWidth
                                        sx={{pt: '8px'}}
                                        onChange={(e) => {
                                            setSearch(search => ({...search, ...{fileName: e.target.value}}))
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        สถานะ:
                                    </Typography>
                                    <Select
                                        fullWidth
                                        sx={{mt: '8px'}}
                                        value={search.status}
                                        size="small"
                                        onChange={(e) => {
                                            setSearch(search => ({
                                                ...search,
                                                ...{status: e.target.value}
                                            }))
                                        }}
                                    >
                                        <MenuItem value={'ทั้งหมด'}>ทั้งหมด</MenuItem>
                                        <MenuItem value={'Waiting'}>Waiting</MenuItem>
                                        <MenuItem value={'Processing'}>Processing</MenuItem>
                                        <MenuItem value={'Done'}>Done</MenuItem>
                                        <MenuItem value={'Fail'}>Fail</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} item sx={{pt: 1}} container
                                      direction="row"
                                      justifyContent="flex-end"
                                      alignItems="end"
                                >
                                    <Button
                                        disabled={isProgress}
                                        variant="contained"
                                        disableElevation
                                        onClick={async () => {
                                            // console.log({
                                            //     date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                            //     fileName: search.fileName,
                                            //     status: search.status,
                                            //     page: page,
                                            //     limit: rowsPerPage,
                                            // })

                                            await fetchData().catch((e) => {
                                                // console.log(e)
                                                setIsLoading(false)
                                                setIsProgress(false)
                                            })
                                        }}
                                        sx={{
                                            // fontWeight: 'bold',
                                            backgroundColor: '#6F9EE8',
                                            color: '#ffffff',
                                            width: '100px',
                                            ':hover': {
                                                backgroundColor: '#4380E1',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                            {/*<Divider />*/}
                        </Grid>
                        <Grid container>
                            <DataGrid
                                sx={{
                                    // minWidth:'1000px',
                                    mt: '15px',
                                    flex: 21,
                                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                    },
                                    '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                        whiteSpace: "normal !important",
                                        wordWrap: "break-word !important",
                                        textAlign: 'center',
                                    }
                                    // minWidth: '60%',
                                }}
                                getRowHeight={() => 58}
                                autoHeight
                                loading={isProgress}
                                // rows={allListData}
                                rows={rows}
                                columnVisibilityModel={columnVisibility}
                                columns={getColumns(columns, JSON.parse(localStorage.getItem("column_import_position")))}
                                slots={{
                                    toolbar: CustomToolbar,
                                    loadingOverlay: LinearProgress,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        setRows,
                                        csvOptions: {disableToolbarButton: true},
                                        printOptions: {disableToolbarButton: true},
                                    },
                                }}
                                initialState={{
                                    pagination: {paginationModel: {pageSize: 10, page: 0}},
                                }}
                                rowSelection={false}
                                disableSelectionOnClick
                                disableDensitySelector
                                disableColumnFilter
                                disableColumnMenu
                                headerAlign="center"
                                pageSize={pageSize}
                                // rowsPerPageOptions={[10, 50, 100]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                pageSizeOptions={[10, 50, 100]}
                            />

                        </Grid>
                    </Box>
            }
            <div>
                <Dialog
                    open={alertOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"แจ้งเตือน"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {Array.isArray(alertData) ? alertData.map((data) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                        >
                                            <Typography>{data}</Typography>
                                        </Grid>
                                    </>
                                )
                            }) : null}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}> ปิด </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )

    function validateTripNo(value) {
        const regex = /^[0-9, _]*$/;

        const tripNo = value.trim()
        const tripNoArr = tripNo?.split(",")

        if ((tripNo.length ?? 0) <= 0) {
            return "กรุณากรอกข้อมูล Trip No."
        }
        else if (tripNoArr.length > 50) {
            return "สามารถแก้ไขข้อมูลเที่ยววิ่งได้ไม่เกินครั้งละ 50 เที่ยววิ่ง"
        }
        // รอสรุปว่าจะให้ดักอะไรบ้าง
        // else if (!regex.test(tripNo)) {
        //     return "กรุณากรอกข้อมูล Trip No. ให้ถูกต้อง"
        // }

        return undefined
    }

    function getColumns(value, localColumns) {
        if (localColumns) {
            const merged = [...columns, ...localColumns].reduce((acc, obj) => {
                const existing = acc.find(item => item.field === obj.field);
                if (existing) {
                    // Merge properties if the object already exists
                    Object.assign(existing, obj);
                } else {
                    // Add new objects to the accumulator
                    acc.push(obj);
                }
                return acc;
            }, []);

            const sorted = merged.sort((a, b) => ((a.index + 1) || Infinity) - ((b.index + 1) || Infinity));

            return sorted
        }

        return value
    }

    function isShowView(value) {
        return Formatter.validateNumber(value) && value > 0
    }

    function checkingUpdateButton(str, isApi, isUpdate) {
        if (isApi && isUpdate) {
            return true
        }

        return str?.toLowerCase() === "makro"
    }

    function checkingImportButton(isApi, isUpdate) {
        if (isApi && isUpdate) {
            return false
        }

        return true
    }

    function checkingTemplateButton(str, isNew) {
        return (str?.toLowerCase()?.includes("makro") && str?.toLowerCase() !== 'makro') || (str?.toLowerCase() === 'makro' && isNew)
    }

    function getTruckrStatus(truckrStatus) {
        let data = []
        truckrStatus.map(m => {
            data.push(m.id)
        })

        return data
    }
}